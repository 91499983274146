import { BackLink } from '@ggs/components/nav'
import React from 'react'
import { useI18n, useLink } from '@ggs/gatsby/lib'

const useBackToParent = () => {
  const safeUrl = useLink(false, false)
  const pathArray = window && window.location.pathname.split('/').filter(function (v) {
    return v
  })

  if (Array.isArray(pathArray) && pathArray.length > 2) {
    const [store = null, language = null, path = null] = pathArray
    const pathValue = `${store}/${language}/${path}`

    return safeUrl({ href: '/' + pathValue })?.uri
  }
  return window?.location?.origin
}

export const useBackToLink = () => {
  const { t } = useI18n()
  const parentLink = useBackToParent()

  return {
    BackToProducts: () => <BackLink
      label={t('ecomm:product.button.backToProducts')}
      href={parentLink}
    />,
    BackToNews: () => <BackLink label={t('news:button.backToNews')} href={parentLink}/>,
    BackToEvents: () => <BackLink label={t('events:button.backToEvents')} href={parentLink}/>
  }
}